
export const useReviewsStore = defineStore('reviews', {
  state: () => ({
    reviews: [],
  }),
  getters: {},
  actions: {
    //old mutations
    SET_REVIEWS (reviews) {
      this.reviews = [ ...reviews ]
    },
    //old actions
    async fetchAll () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: result } = await apiFetch(`/${locale}/reviews-latest`)
        this.SET_REVIEWS(toUnixNewlines(result, ['text']))

        return toUnixNewlines(result, ['text'])
      } catch (err) {
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage('reviews: Error fetching latest reviews', {
          level: 'warning',
          contexts: {
            details: err.data || err
          },
          tags: {
            type: 'UX'
          }
        })
        return null
      }
    },
  },
})

